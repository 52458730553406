import { SLOT_MAPPING } from "../constants/value";

export const portComparator = (v1, v2) => {
  const match1 = v1 ? v1.match(/(\d+)([a-z]|[A-Z]+)/) : null;
  const match2 = v2 ? v2.match(/(\d+)([a-z]|[A-Z]+)/) : null;

  if (!match1 && !match2) {
    return 0;
  }
  if (!match1 && match2 && match2.length) {
    return 1;
  }
  if (!match2 && match1 && match1.length) {
    return -1;
  }

  const slot1 = SLOT_MAPPING[match1[2]];
  const slot2 = SLOT_MAPPING[match2[2]];

  return +match1[1] + +slot1 * 100 - (+match2[1] + +slot2 * 100);
};

export const isUltraSim = (iccid) =>
  iccid.startsWith("8901260853") ||
  iccid.startsWith("89012401") ||
  iccid.startsWith("89012403");

export const isTelitSim = (iccid) =>
  iccid.startsWith("89010303") ||
  iccid.startsWith("89010304") ||
  iccid.startsWith("89014104");

export const matchCodes = (text) => {
  const regex = new RegExp(
    /(?:is|is:|otp|password|key|code|CODE|KEY|OTP|PASSWORD).([0-9]{4,8})|([0-9]{4,8}).(?:is|is:|otp|password|key|code|CODE|KEY|OTP|PASSWORD) | access code: (.*)/g
  );

  return Array.from(text.matchAll(regex), (m) => {
    return m[1] || m[2] || m[3];
  });
};

export const generatePassword = () => {
  const length = 20;
  const charset =
    "0123456789abcdefghijklmnopqrstuvwxyz!ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};
