import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import LockIcon from "@mui/icons-material/Lock";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { Box, Button, InputLabel, OutlinedInput } from "@mui/material";

import Title from "../../common/Title";
import useAuth from "../../../hooks/useAuth";
import useAxios from "../../../hooks/useAxios";
import useAlert from "../../../hooks/useAlert";
import { __getUserList } from "../../../api/user";
import { generatePassword } from "../../../utils/data";
import { __getGroups, __updateUser } from "../../../api/group";
import InputFieldAutoComplete from "../../common/InputFieldAutoComplete";

const UpdateUser = () => {
  const { user } = useAuth();
  const nav = useNavigate();

  if (user.sub_user) {
    nav("/mdn");
  }

  const [userResponse, userCall, userApiLoading] = useAxios(__getUserList);
  const [groupResponse, groupCall, groupApiLoading] = useAxios(__getGroups);

  const { setAlertMessage } = useAlert();
  const [groups, setGroups] = useState([]);
  const [currentGroup, setCurrentGroup] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState(null);
  const [response, call, loading] = useAxios({
    ...__updateUser,
  });
  const initialData = { password: "" };

  const validationSchema = yup.object({
    password: yup.string("password").optional().min(4),
    forward_url: yup.string("forward_url").optional(),
    api_key: yup
      .string("api_key")
      .nullable()
      .max(50, "Cannot be more than 50 characters long.")
      .optional(),
  });

  useEffect(() => {
    if (currentUser) {
      const user = users.find((user) => user.id === currentUser.id);
      formik.setFieldValue("forward_url", user.forward_url || "");
    } else formik.setFieldValue("forward_url", "");
  }, [currentUser]);

  useEffect(() => {
    if (formData) {
      call({
        url: `user/${currentUser.id}`,
        data: { ...formData },
      });
    }
  }, [formData]);

  useEffect(() => {
    formik.setSubmitting(false);
    if (!response) return;
    if (response.status) {
      setAlertMessage({ type: "success", message: "User Updated" });
      nav(`/group`);
    }
  }, [response]);

  useEffect(() => {
    userCall({ params: { page_size: 1000 } });
    user.admin && groupCall({ params: { page_size: 1000 } });
  }, []);

  useEffect(() => {
    if (userResponse && userResponse.status) {
      setUsers(userResponse.data.data);
    }
  }, [userResponse]);

  useEffect(() => {
    if (groupResponse && groupResponse.status) {
      setGroups(groupResponse.data.data);
      groupResponse.data.data.length === 1 &&
        setCurrentGroup(groupResponse.data.data[0]);
    }
  }, [groupResponse]);

  const formik = useFormik({
    initialValues: { ...initialData },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const requestData = Object.keys(values).reduce((obj, key) => {
        if (key === "password" && !values[key]) {
          return obj;
        }
        obj[key] = values[key] || "";
        return obj;
      }, {});

      setFormData(requestData);
    },
    validateOnChange: true,
  });

  const generateAndSetPassword = () => {
    formik.setFieldValue("api_key", generatePassword());
  };

  if (loading || userApiLoading || groupApiLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <>
      <Box
        sx={{ width: "100%", mb: 5 }}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Title variant="h5" content="Update User" sx={{ mb: 3 }} gutterBottom />
        <Grid
          sx={{ flexGrow: 1 }}
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
          maxWidth={350}
        >
          {user.admin && (
            <Grid item xs={12} minWidth={"100%"}>
              <InputFieldAutoComplete
                isSelect
                options={[
                  ...groups.map((group) => ({
                    ...group,
                    label: `${group.group_name}`,
                  })),
                ]}
                name="group"
                label="Group"
                id="group"
                disabled={groups.length === 1}
                value={currentGroup?.group_name}
                isOptionEqualToValue={(option, value) =>
                  option && value && option.id === value.id
                }
                onChange={(e, value) => {
                  setCurrentGroup(value);
                  setCurrentUser(null);
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} minWidth={"100%"}>
            <FormControl
              error={
                formik.touched.user && Boolean(formik.errors.user)
                  ? true
                  : false
              }
              sx={{ width: "100%" }}
            >
              <InputFieldAutoComplete
                isSelect
                options={[
                  ...users.reduce((acc, subUser) => {
                    if (user.admin && subUser.group_id !== currentGroup?.id) {
                      return acc;
                    }
                    const val = {
                      ...subUser,
                      label: `${subUser.username}${
                        subUser.admin ? "*" : subUser.sub_user ? "^" : ""
                      }`,
                      group_id: subUser.group_id,
                    };
                    acc.push(val);
                    return acc;
                  }, []),
                ]}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                name="user"
                label="User"
                id="user"
                onChange={(e, value) => setCurrentUser(value)}
              />
              {formik.touched.user && Boolean(formik.errors.user) ? (
                <FormHelperText id="user-error-text">
                  {formik.errors.user}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} minWidth={"100%"}>
            <FormControl
              error={Boolean(formik.errors.password) ? true : false}
              sx={{ width: "100%" }}
            >
              <InputLabel htmlFor="password">Password</InputLabel>
              <OutlinedInput
                name="password"
                label="Password"
                id="password"
                handleBlur={formik.handleBlur}
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              {Boolean(formik.errors.password) ? (
                <FormHelperText id="password-error-text">
                  {formik.errors.password}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} minWidth={"100%"}>
            <FormControl
              error={Boolean(formik.errors.api_key) ? true : false}
              sx={{ width: "100%" }}
            >
              <InputLabel htmlFor="api_key">Api Key</InputLabel>
              <OutlinedInput
                name="api_key"
                label="Api Key"
                id="api_key"
                value={formik.values.api_key || ""}
                onChange={formik.handleChange}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={generateAndSetPassword}
              >
                Generate Api Key
                <LockIcon />
              </Button>
              {Boolean(formik.errors.api_key) ? (
                <FormHelperText id="api_key-error-text">
                  {formik.errors.api_key}
                </FormHelperText>
              ) : (
                <FormHelperText id="api_key-info-text">
                  {
                    "Please keep this key safe, this will not be available in the future."
                  }
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} minWidth={"100%"}>
            <FormControl
              error={Boolean(formik.errors.forward_url) ? true : false}
              sx={{ width: "100%" }}
            >
              <InputLabel htmlFor="forward_url">Forward URL</InputLabel>
              <OutlinedInput
                name="forward_url"
                label="Forward URL"
                id="forward_url"
                handleBlur={formik.handleBlur}
                value={formik.values.forward_url}
                onChange={formik.handleChange}
                defaultValue={""}
              />
              {Boolean(formik.errors.forward_url) ? (
                <FormHelperText id="forward_url-error-text">
                  {formik.errors.forward_url}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>

          <Grid
            container
            sx={{ mt: 3 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <LoadingButton
                style={{ marginRight: "5px" }}
                disabled={Object.keys(formik.errors).length || !currentUser?.id}
                onClick={() =>
                  currentUser?.id &&
                  !loading &&
                  !formik.isSubmitting &&
                  formik.submitForm()
                }
                size="large"
                loading={loading}
                variant="contained"
              >
                Update
              </LoadingButton>
            </Grid>
            <Grid item>
              <LoadingButton
                style={{ marginRight: "5px" }}
                size="large"
                onClick={() => nav("/group")}
                loading={loading}
                variant="contained"
              >
                Cancel
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UpdateUser;
